import { get, post, put, Delete } from "../axios";

// 产品公告-列表
export const newsNoticeList = (params) => get("/web/news/notice/list", params);

// 产品公告-新增
export const newsNoticeAdd = (params) => post("/web/news/notice/add", params);

// 产品公告-编辑
export const newsNoticeUpdate = (params) =>
  put("/web/news/notice/update", params);

// 产品公告-删除
export const newsNoticeDelete = (id) =>
  Delete("/web/news/notice/delete/" + id, "");

// 产品公告-详情
export const newsNoticeview = (id) => get("/web/news/notice/view/" + id, "");

// 产品公告-首页推荐-状态编辑
export const newsNoticeHomeUpdate = (params) =>
  put("/web/news/notice/home/update", params);
