<template>
  <div class="flex">
    <draggable
      v-model="url"
      filter=".forbid"
      chosenClass="chosen"
      forceFallback="true"
      group="key"
      animation="1000"
      @start="onStart"
      @end="onEnd"
    >
      <transition-group class="flex flex-wrap">
        <div
          class="ElImage"
          v-for="(item, index) in url"
          :key="`ElImage${index}`"
        >
          <!-- <el-image
            class="ElImageItem"
            :src="item"
            :key="`ElImage${index}`"
            fit="contain"
          >
          </el-image> -->
          <video :src="item" :key="`ElImage${index}`"></video>
          <div class="ElImageHover">
            <div class="flex">
              <i
                class="el-icon-zoom-in ElIcon"
                @click.stop="handleView(index)"
              ></i>
              <i
                v-if="!disabled"
                class="el-icon-delete ElIcon"
                style="margin-left: 15px"
                @click.stop="handleDelete(index)"
              ></i>
            </div>
          </div>
        </div>
        <div class="forbid" :key="`ElImage${10}`" v-if="url.length < limit">
          <el-upload
            class="avatar-uploader"
            action=""
            :show-file-list="false"
            :file-list="url"
            list-type="picture-card"
            :multiple="multiple"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-exceed="handleExceed"
            :limit="limit"
            :disabled="disabled || showUpLoad"
            :accept="accept"
            :http-request="uploadRequest"
          >
            <div v-if="showUpLoad">正在上传</div>
            <i v-else class="el-icon-plus"></i>
          </el-upload>
          <!-- <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="url" alt="" />
          </el-dialog> -->
        </div>
      </transition-group>
    </draggable>
    <el-dialog
      v-if="dialogVisible"
      title="预览"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
      :close-on-click-modal="false"
      append-to-body
    >
      <video
        :src="url[imgViewerIndex]"
        style="width: 100%; height: 400px"
        controls
      ></video>

      <!-- <el-button @click="handleClose"> 关闭</el-button> -->
    </el-dialog>
    <!-- <el-image-viewer
      v-if="imgViewerVisible"
      :on-close="closeImgViewer"
      :initialIndex="imgViewerIndex"
      :url-list="url"
    /> -->
  </div>
  <!-- <div>
    <el-upload
      class="avatar-uploader"
      :action="actionUrl"
      :show-file-list="false"
      list-type="picture-card"
    >
   
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="url" alt="" />
    </el-dialog>
  </div> -->
</template>

<script>
// import { actionUrl, imageUrl } from "@/config/public";
import { toolsts } from "@/api";
import { guid } from "@/utils/other.js";
import draggable from "vuedraggable";
// import { uploadRequest } from "@/components/aliyun";
// import { guid } from "@/utils/other.js";
// import { toolsts } from "@/api";
import ObsClient from "esdk-obs-browserjs";

import OSS from "ali-oss";

export default {
  name: "Test",
  components: {
    draggable,
    "el-image-viewer": () =>
      import("element-ui/packages/image/src/image-viewer"),
  },
  data() {
    return {
      // imageUrl: imageUrl,
      // action: this.actionUrl,
      dialogImageUrl: "",
      dialogVisible: false, //预览弹框
      imgViewerVisible: false, // 图片预览
      client: null, //oss
      showUpLoad: false, //图片是否正在上传
    };
  },
  props: {
    maxSize: {
      type: [Number],
      default: 2,
    }, //图片大小
    accept: {
      type: String,
      default: "image/*,image/jpeg,image/svg,image/gif",
    }, //上传格式
    maxCount: {
      type: [String, Number],
      default: 1,
    }, //图片数量
    multiple: {
      type: Boolean,
      default: false,
    }, //是否支持多选
    disabled: {
      type: Boolean,
      default: false,
    }, //是否禁用
    limit: {
      type: [String, Number],
      default: 1,
    }, //最大允许上传个数

    url: {
      type: Array,
      default: [],
    },
    indexof: {
      type: Number,
      default: 0,
    },
  },
  created() {
    // console.log(this.url, "urlurlurl");
    // this.getList();
  },
  methods: {
    //上传阿里云
    async uploadRequest(option) {
      try {
        const self = this;
        let type = option.file.type;
        let size = option.file.size;
        let pos = option.file.name.lastIndexOf(".");
        let filename = option.file.name.substring(0, pos);
        let extendName = option.file.name.substring(pos + 1);
        // let date = new Date().getTime();
        let fileNames = `backstage/${guid()}.${extendName}`; // 拼接文件名，保证唯一，这里使用时间戳+原文件名

        let obsClient;
        toolsts().then(async (res) => {
          if (res.code === 200) {
            const { access, secret, bucket, endpoint, securityToken, ak, sk } =
              res.data;
            obsClient = new ObsClient({
              access_key_id: ak,
              secret_access_key: sk,
              server: `https://${endpoint}`,
              x_obs_acl: "public-read",
            });
            obsClient.putObject(
              {
                Bucket: bucket,
                Key: fileNames,
                // SourceFile: option.file, // localfile为待上传的本地文件路径，需要指定到具体的文件名
                Body: option.file,
                ACL: "public-read",
              },
              (err, result) => {
                if (err) {
                  this.$message.error("华为云上传失败");
                  this.$emit("upload", this.url);
                  this.showUpLoad = false;
                  return Promise.reject(e);
                } else {
                  if (result.CommonMsg.Status == 200) {
                    // 上传之后的文件地址
                    let restImg = `https://${bucket}.${endpoint}/${fileNames}`;
                    res = restImg;
                    option.onSuccess(res); //触发el-upload组件的onsuccess方法
                    this.showUpLoad = false;
                  }
                }
              }
            );
          } else {
            this.$message.error(res.msg);
          }
        });
      } catch (e) {
        this.$message.error("华为云上传失败");
        this.$emit("upload", this.url);
        this.showUpLoad = false;
        return Promise.reject(e);
      }
    },
    getOss() {
      toolsts()
        .then((res) => {
          if (res.code === 200) {
            const {
              accessKeyId,
              accessKeySecret,
              stsToken,
              bucket,
              region,
              endpoint,
            } = res.data;
            this.client = new OSS({
              endpoint: endpoint,
              accessKeyId: accessKeyId,
              accessKeySecret: accessKeySecret,
              stsToken: stsToken,
              bucket: bucket,
              // secure: true,
              region: region,
            });
            // return client;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => console.log(this.$refs.myForm));
    },
    /*
     *
     * 上传文件数据至数据库米
     * @param{*} param 文件信息
     */

    async onCreatePanFile(param) {
      const submitRes = await submitServe(param);
      const { result } = submitRes;
      if (!this.multiple) {
        this.thumbList = [];
      }
      let paramData = {
        id: result,
        url: param.url,
        name: param.fileName,
      };
      // this.$emit("loadFileSuccess", paramData);
    },

    //删除
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    //查看当前图片
    handlePictureCardPreview(file) {
      this.dialogVisible = true;
    },
    //上传图片
    handleAvatarSuccess(res, file) {
      console.log(res, "resresres");
      if (res) {
        this.$emit("upload", res, "", "", "", this.indexof);
      }
    },
    //上传图片前进行格式校验
    beforeAvatarUpload(file) {
      this.showUpLoad = true;
      console.log(file, "uFilesuFilesuFiles");
      // console.log(file, "this.actionUrlthis.actionUrlthis.actionUrl");
      const isLt2M = file.size / 1024 / 1024 < this.maxSize;
      const isJPG =
        this.accept.indexOf(
          file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase()
        ) > -1;
      const isNum = this.url.length < this.limit;
      if (!isJPG) {
        this.$message.error("上传格式不正确");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 " + this.maxSize + "MB!");
      }
      this.showUpLoad = isJPG && isLt2M;

      return isJPG && isLt2M;
    },
    //文件超出个数限制时的钩子
    handleExceed(files, fileList) {
      this.$message.error(`超出最大允许上传数量 (${this.limit})`);
      this.showUpLoad = false;
    },
    //预览
    handleView(index) {
      this.imgViewerIndex = index;
      this.dialogVisible = true;
      const m = (e) => {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", m, false); // 禁止页面滑动
    },
    closeImgViewer() {
      this.imgViewerVisible = false;
    },
    //删除当前图片
    handleDelete(index) {
      this.$confirm("是否确定移除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            let newUrl = [...this.url].filter(
              (item, indexs) => indexs != index
            );
            console.log(newUrl, "newUrlnewUrlnewUrl");
            this.$emit("upload", newUrl, "", "", "", this.indexof);
          }
          done();
        },
      });
    },
    //开始拖拽事件
    onStart() {
      this.drag = true;
    },
    //拖拽结束事件
    onEnd() {
      this.drag = false;
    },
    // 关闭预览
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
/deep/ .avue-form__group .avue-upload__icon {
  width: 148px !important;
  height: 148px !important;
  line-height: 148px !important;
}
/deep/ .avue-form__group .avue-upload__avatar {
  width: 148px !important;
  height: 148px !important;
  object-fit: contain;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  object-fit: contain;
}
.ElImage {
  width: 148px !important;
  height: 148px !important;
  border: 1px solid #c0ccda;
  border-radius: 6px;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
}
.ElImage .ElImageItem {
  width: 148px !important;
  height: 148px !important;
}
.ElImageHover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s;
  opacity: 0;
}
.ElImageHover:hover {
  opacity: 1;
}
.ElIcon {
  color: #fff;
  font-size: 20px;
}
::v-deep .el-image-viewer__wrapper {
  /* z-index: 9999!important; */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
/deep/.el-dialog__wrapper {
  z-index: 99999999 !important;
}
</style>
